import api from "./api/api";

export function getAllPlans() {
  return api.get("dentists/razorpay-plans");
}

export function createSubscriptionLink(data) {
  return api.post("dentists/razorpay-subscription", data);
}

export function getSubscriptionStatus(id) {
  return api.get(`dentists/razorpay-subscription-status/${id}`);
}

export function getAllSubscriptions(data) {
  return api.post("dentists/razorpay-subscriptions", data);
}

export function getAllPayments(id, data) {
  return api.post(`dentists/razorpay-subscription-history/${id}`, data);
}

export function cancelSubscription(id, data) {
  return api.post(`dentists/subscription/cancel/${id}`, data);
}

export function getAllPaymentHistory(data) {
  return api.post("dentists/razorpay-payment-history", data);
}

export function fetchSubscriptionInfo(id) {
  return api.get(`dentists/raz-sub/${id}`);
}
