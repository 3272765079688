import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  onSignInSuccess,
  onSignOutSuccess,
  setToken,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { login } from "services/authServices";
import { useEffect, useState } from "react";
import { getSubscriptionStatus } from "services/razorpayServices";

function useAuth() {
  const user = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const { token, signedIn, expired } = useSelector(
    (state) => state.auth.session
  );

  const [subscriptions, setSubscriptions] = useState();
  const fetchSubDetails = async (userId) => {
    try {
      const resp = await getSubscriptionStatus(userId);
      if (resp?.data?.success) {
        console.log("resp?.data?.data?.status", resp?.data?.data?.status);
        setSubscriptions(resp?.data?.data);
        return resp?.data?.data?.status;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signIn = async (data) => {
    try {
      const resp = await login(data);
      // console.log("useAuth", resp)

      if (resp?.data?.data?.token) {
        const token = resp?.data?.data?.token;
        // console.log("token", token)
        dispatch(setToken(token));
        dispatch(onSignInSuccess(token));

        if (resp.data.data) {
          const userData = resp.data.data;
          console.log("userData", userData);
          dispatch(
            setUser({
              userName: userData?.firstName + " " + userData?.lastName,
              // avatar: userData?.profileImage,
              id: userData?._id,
              authority: userData?.dentist?.specialization
                ? userData?.dentist?.specialization
                : "dentist",
              email: userData?.email ? userData?.email : "test@gmail.com",
            })
          );
        }

        // const redirectUrl = query.get(REDIRECT_URL_KEY);
        // console.log("redirectUrl: ", redirectUrl);

        // navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        const status = await fetchSubDetails(resp.data.data?._id);
        console.log("🚀 ~ signIn ~ status:", status);
        const redirectUrl =
          query.get(REDIRECT_URL_KEY) || appConfig.authenticatedEntryPath;
        navigate(redirectUrl, {
          state: {
            showDialog: status !== "active",
          },
        });

        return {
          status: "success",
          message: "login done",
        };
      } else {
        return {
          status: "failed",
          message: "Failed to log in, Please Check Your Credentials",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  const checkAuthenticated = () => {
    if (expired > new Date().getTime()) {
      return true;
    } else {
      handleSignOut();
      return false;
    }
  };

  return {
    authenticated: token && signedIn && checkAuthenticated(),
    signIn,
    signOut,
  };
}

export default useAuth;
